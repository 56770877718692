:root {
	--facebook-color: #0866ff;
	--pinterest-color: #e71c27;
	--twitter-color: #00aced;
	--instagram-color: #517fa4;
	--youtube-color: #cd201f;
	--houzz-color: #4dbc15;
	--linkedin-color: #0077b5;
}

.facebookHover:hover {
	color: var(--facebook-color);
}

.pinterestHover:hover {
	color: var(--pinterest-color);
}

.instagramHover:hover {
	color: var(--instagram-color);
}

.youtubeHover:hover {
	color: var(--youtube-color);
}

.houzzHover:hover {
	color: var(--houzz-color);
}

.linkedinHover:hover {
	color: var(--linkedin-color);
}

@media (--breakpoint-not-small) {
	.socialSectionHeight {
		height: 44px;
	}
}

:global(.omni) .omniSocialSectionHeight {
	@media (--breakpoint-not-small) {
		height: 37px;
	}
}

.socialIconsGap {
	gap: 2rem;
}

.socialPaddingTop {
	padding-top: 0.125rem;
}
