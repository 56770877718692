/* this is the theme-review rgb value */
.locationWarningBanner {
	background: rgb(248 186 31 / 20%);
	border: none;
}

.warningBannerGap {
	gap: 1.25rem;
}

.warningIcon {
	height: 2rem !important;
	width: 2rem !important;
}

.locationSummaryBox {
	margin-right: 0;
}

.gallery {
	/* make it fill the screen width */
	width: calc(100% + 1rem);
}

.locationInfoSection {
	display: grid;
	grid-template-rows: repeat(3, auto);
	gap: 2rem;
}

.prepareForAppointmentGrid {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
}

.proAssociationGrid {
	display: grid;
	gap: 2.5rem;
	grid-template-columns: repeat(4, 1fr);
}

@media (--breakpoint-not-small) {
	.locationSummaryBox {
		margin-right: -48px;
	}

	.locationInfoSection {
		grid-template-rows: 1fr;
		grid-template-columns: 1fr 3px 1fr 3px 1fr;
	}

	.prepareForAppointmentGrid {
		grid-template-columns: repeat(2, 1fr);
	}

	.proAssociationGrid {
		grid-template-columns: repeat(auto-fill, 100px);
	}
}

@media (--breakpoint-medium) {
	.gallery {
		width: 70%;
	}
}

@media (--breakpoint-large) {
	.gallery {
		width: 60%;
	}
}

.locationSummaryContainer {
	bottom: 4rem;
}
